import GoogleIcon from 'mdi-vue/Google';
import FIcon from 'mdi-vue/Facebook';
import Twitter from 'mdi-vue/Twitter';
import Github from 'mdi-vue/Github';
import Microsoft from 'mdi-vue/Microsoft';
import Yahoo from 'mdi-vue/Yahoo';
import firebase from './firebase.config';

export const providers = [
  { id: 'google.com', icon: GoogleIcon },
  { id: 'facebook.com', icon: FIcon },
  { id: 'twitter.com', icon: Twitter },
  { id: 'github.com', icon: Github },
  { id: 'microsoft.com', icon: Microsoft },
  { id: 'yahoo.com', icon: Yahoo },
];

export function getAuthProvider(providerId) {
  switch (providerId) {
    case 'google.com':
      return new firebase.auth.GoogleAuthProvider();
    case 'facebook.com':
      return new firebase.auth.FacebookAuthProvider();
    case 'twitter.com':
      return new firebase.auth.TwitterAuthProvider();
    case 'github.com':
      return new firebase.auth.GithubAuthProvider();
    case 'microsoft.com':
      return new firebase.auth.OAuthProvider(providerId);
    case 'yahoo.com':
      return new firebase.auth.OAuthProvider(providerId);

    default:
      return new firebase.auth.EmailAuthProvider();
  }
}
