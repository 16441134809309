<template>
  <div class="profile">
    <h2>Profile</h2>
    <div class="card p-3">

      <template v-if="!user.emailVerified">
      <div class="row">
        <div class="col-md-4">
          <h5 class="text-danger">Account not verified</h5>
          <p>Verify your email to unlock more features</p>
        </div>
        <div class="col-md-8">
          <div>
            <b-link class="ml-2" @click.prevent="sendEmail">Re-send verification email</b-link>.
          </div>
        </div>
      </div>
      <hr />
      </template>

      <div class="row">
        <div class="col-md-4">
          <h5>Public Avatar</h5>
          <p>You can change your avatar at <a href="https://www.gravatar.com/" target="_blank">gravatar.com</a></p>
        </div>
        <div class="col-md-8">
          <div class="profile_img">
            <img :src="user.photoURL" :alt="user.displayName"/>
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-md-4">
          <h5>Personal Information</h5>
          <p>Enter your name so people you know can recognize you</p>
        </div>
        <div class="col-md-8">
          <div class="personal_info">
            <div>
              <span class="item">Name:</span>
              <span><strong>{{user.displayName}}</strong></span>
              <b-link class="ml-2" to="">Edit</b-link>
            </div>
            <div>
              <span class="item">Email:</span>
              <span>{{user.email}}</span>
            </div>
            <div>
              <span class="item">Phone:</span>
              <span>{{user.phoneNumber}}</span>
            </div>
            <div>
              <span class="item">Roles:</span>
              <span><b-badge class="mr-1" v-for="(role, i) in userRoles" :key="i">{{role}}</b-badge></span>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-md-4">
          <h5>Social Sign-in</h5>
          <p>Connect your account with the following services</p>
        </div>
        <div class="col-md-8">
          <p>Click on icon to activate / deactivate signin with one of the following services.</p>
          <ul class="social">
            <li v-for="p in providers" :key="p.id">
              <div class="btn-group" role="group" :aria-label="p.id" @click="disconnect(p.id)" v-if="connectedProviders.includes(p.id)">
                <button type="button" class="btn btn-sm btn-outline-primary"><component :is="p.icon"></component></button>
                <button type="button" class="btn btn-sm btn-outline-primary btn-light">Disconnect</button>
              </div>
              <div class="btn-group" role="group" :aria-label="p.id" @click="connect(p.id)" v-else>
                <button type="button" class="btn btn-sm btn-outline-primary"><component :is="p.icon"></component></button>
                <button type="button" class="btn btn-sm btn-outline-primary btn-light">Connect</button>
              </div>

            </li>
          </ul>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-md-4">
          <h5 class="text-danger">Delete Account</h5>
          <p>Irreversible and destructive action</p>
        </div>
        <div class="col-md-8">
          <p>Once you delete your account, there is no going back. Please be certain.</p>
          <div>
            <button id="delete-pop" class="btn btn-sm btn-danger">Delete account</button>
            <b-popover target="delete-pop" ref="pop" triggers="click" :show.sync="popoverShow" @show="disableButton">
              <template v-slot:title>
                <b-button @click="popoverShow = false" class="close" aria-label="Close">
                  <span class="d-inline-block" aria-hidden="true">&times;</span>
                </b-button>
                Delete Account
              </template>
              <p>We hate to see you go.</p>
              <p>Are you really sure?</p>
              <div class="form-group" v-if="showPassword">
                <input class="form-control" type="password" ref="password" required placeholder="Enter your password" />
              </div>
              <button class="btn btn-sm btn-block btn-danger" :disabled="disabled" @click="deleteAccount">
                Yes I understand!<br />Delete <span v-if="disabled">({{secs}})</span>
              </button>
            </b-popover>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import firebase from '@/store/modules/auth/firebase.config';
import { providers, getAuthProvider } from '@/store/modules/auth/providers';

const CountdownTimeout = 5; // Count down timeout in seconds

export default {
  name: 'Profile',
  data() {
    return {
      disabled: true,
      secs: 0,
      interval: null,
      providers,
      popoverShow: false,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'isLoggedIn']),
    ...mapGetters('auth', ['currentUser']),
    connectedProviders() {
      return this.user.providerData ? this.user.providerData.map(x => x.providerId) : [];
    },
    showPassword() {
      return this.connectedProviders.includes('password');
    },
    userRoles() {
      let usrRoles = [];
      if (this.user.claims && this.user.claims.roles) usrRoles = [...this.user.claims.roles];
      return usrRoles.sort();
    },
  },
  methods: {
    sendEmail() {
      this.$store.dispatch('auth/resendVerificationEmail').then(() => {
        this.$toast.success('Verication Email has been sent');
      }).catch(() => {
        this.$toast.success('We cannot send Verication Email at this time. Try again later.');
      });
    },
    connect(providerId) {
      const provider = getAuthProvider(providerId);
      this.currentUser.linkWithPopup(provider).then(res => this.handleAccountLinkingSuccess(res.user));
    },
    disconnect(providerId) {
      this.currentUser.unlink(providerId).then(user => this.handleAccountLinkingSuccess(user));
    },
    handleAccountLinkingSuccess(user) {
      this.$store.dispatch('auth/fetchUser', user);
    },
    disableButton() {
      this.secs = CountdownTimeout;
      this.disabled = true;
      if (this.interval) clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.secs -= 1;
        if (this.secs <= 0) {
          this.disabled = false;
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 1000);
    },
    deleteAccount() {
      const user = this.currentUser;
      if (this.showPassword) {
        const pwd = this.$refs.password.value;
        if (!pwd) {
          return this.handleError();
        }
        const cred = firebase.auth.EmailAuthProvider.credential(this.user.email, pwd);
        user.reauthenticateWithCredential(cred).then(() => this.handleDeleted(user)).catch(this.handleError);
      } else {
        const signInProvider = this.user.claims.firebase.sign_in_provider;
        const provider = getAuthProvider(signInProvider);
        user.reauthenticateWithPopup(provider).then(() => this.handleDeleted(user)).catch(this.handleError);
      }

      return null;
    },
    handleDeleted(user) {
      user.delete().then(() => {
        this.$nextTick(() => { this.$router.replace({ name: 'logout' }); });
      }).catch(() => {
        this.$toast.error('Unable to delete account at this time. Try again later.', 'Unknown Error');
      });
    },
    handleError() {
      this.$toast.error('Account cannot be deleted.', 'Invalid Credentials');
    },
  },

};
</script>
<style lang="stylus">

.profile
  position relative
  .profile_img
    display inline-block
    text-align center
    img
      width 5rem
  .personal_info
    .item
      display inline-block
      min-width 4rem
  .social
    li
      list-style none
      display inline-block
      margin 0 0.5rem 0.5rem 0

</style>
